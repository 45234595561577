var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"},{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.aosVisibilityChanged,
    once: true,
    throttle: 150,
    intersection: {
      rootMargin: '-86px 0px',
      threshold: 0,
    },
  }),expression:"{\n    callback: aosVisibilityChanged,\n    once: true,\n    throttle: 150,\n    intersection: {\n      rootMargin: '-86px 0px',\n      threshold: 0,\n    },\n  }"}],staticClass:"faq -s-m-120",attrs:{"id":_vm.blok.id || null}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"faq__wr"},[_c('div',{staticClass:"faq__inner"},[(_vm.blok.title)?_c(_vm.heading,{tag:"component",staticClass:"faq__title -h1 -ws-pl",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up"},domProps:{"innerHTML":_vm._s(_vm.blok.title)}}):_vm._e(),_vm._v(" "),(_vm.blok.list && _vm.blok.list.length)?_c('div',{staticClass:"faq__list"},_vm._l((_vm.blok.list),function(item,idx){return _c('div',{key:item._uid,staticClass:"faq__list-item",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up","data-aos-delay":(150 * idx) + 150}},[_c('SlideXRightTransition',{attrs:{"delay":item._uid === _vm.activeItem ? 150 : 0,"duration":item._uid === _vm.activeItem ? 300 : 150}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(item._uid === _vm.activeItem),expression:"item._uid === activeItem"},{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"faq__list-item-easy hide-mob",attrs:{"data-src":require("assets/img/icons/faq-easy.svg"),"alt":"Easy","width":"100","height":"140","data-manual-lazy":""}})]),_vm._v(" "),_c('BaseAccordion',{directives:[{name:"editable",rawName:"v-editable",value:(item),expression:"item"}],attrs:{"title":item.title,"active":item._uid === _vm.activeItem},on:{"click":function($event){return _vm.handleClick(item._uid)}}},[_c('BaseRichtext',{staticClass:"faq__list-item-descr -t3",attrs:{"doc":item.description}})],1)],1)}),0):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }