//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SlideXRightTransition } from 'vue2-transitions';

export default {
  name: 'BlokHomeFaq',
  components: {
    SlideXRightTransition,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      activeItem: '',
      isVisibleAos: false,
    }
  },
  computed: {
    aosAnimate() {
      return this.isVisibleAos ? 'aos-animate' : null;
    },
    heading() {
      switch(this.index) {
        case 0:
          return 'h1';
        default:
          return 'h2';
      }
    },
  },
  methods: {
    handleClick(id) {
      this.activeItem = id === this.activeItem ? '' : id;
    },
    aosVisibilityChanged(isVisible) {
      this.isVisibleAos = isVisible;

      if (isVisible) this.lazyLoadImage();
    },
    lazyLoadImage() {
      let media = this.$el.querySelectorAll('[data-manual-lazy]');
      [...media].forEach(m => this.$lazyLoad(m));
    },
  },
}
